<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        ตั้งค่า
        <v-icon>mdi-chevron-right</v-icon>
        ค่าขนส่งมาตรฐาน
      </v-card-title>
    </v-card>

    <!-- <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">coming soon!!!</span>
      </v-card-title>
      <v-divider/>
    </v-card> -->

    <!-- card2 -->
    <v-card>
      <v-card-title>
        <v-icon size="20" color="success">fa-truck</v-icon
        ><span class="my-3 ml-3">ขนส่งของฉัน</span>
        <v-spacer />
        <v-btn
          :elevation="1"
          color="primary"
          @click="handleOpenDialogEditExpress(null, false)"
        >
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          <span class="fn-14"><b> เพิ่มขนส่ง </b></span>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div>
          <v-row class="mt-2 mx-0">
            <v-col cols="12" class="px-0">
              <!-- PC -->
              <v-data-table
                :headers="my_express.headers"
                :key="0"
                :items="my_express.dataset"
                hide-default-footer
                item-key="id"
                :items-per-page="10000"
                class="elevation-0 packhai-border-table"
              >
                <template v-slot:item.no="{ item }">
                  {{ my_express.dataset.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <v-row>
                    <v-col cols="12" align="left">
                      <img :src="item.expressCompanyLogo" width="60px" />
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.Name="{ item }">
                  <v-row>
                    <v-col cols="12" align="left">
                      <span v-if="item.isOasysAccount">
                        <img src="@/assets/logo_oasys.png" width="20px" height="20px" />{{
                          "Oasys account"
                        }}
                      </span>
                      <span v-else>{{ item.logisticAccountShortName }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.COD="{ item }">
                  <v-icon
                    v-if="item.isAllowCod != null && item.isAllowCod"
                    color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    v-else-if="item.isAllowCod != null && !item.isAllowCod"
                    color="error"
                    >mdi-close-circle</v-icon
                  >
                </template>
                <template v-slot:item.isActive="{ item }">
                  <v-row justify="center">
                    <a v-if="item.isAllowActive" @click="handleUpdateIsActive(item)">
                      <v-switch
                        v-model="item.isActive"
                        readonly
                        color="success"
                      ></v-switch>
                    </a>
                  </v-row>
                </template>
                <template v-slot:item.viewDialogMyexpress="{ item }">
                  <a
                    v-if="
                      item.expressCompanyId != 0 &&
                      item.expressCompanyId != 8 &&
                      item.expressCompanyId != 9 &&
                      item.expressCompanyId != 15 &&
                      item.expressCompanyId != 16
                    "
                    @click="handleOpenDialogExpress(item, 1)"
                    >ตั้งค่าค่าขนส่ง</a
                  >
                </template>
                <template v-slot:item.viewDialogPackSetting="{ item }">
                  <a @click="handleOpenDialogExpress(item, 2)">ตั้งค่าการแพ็ค</a>
                </template>
                <template v-slot:item.statusMessage="{ item }">
                  <v-row>
                    <v-col cols="12" align="left">
                      <span v-if="item.statusMessage === 'พร้อมใช้งาน'"
                        ><v-icon size="10" color="success"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        พร้อมใช้งาน</span
                      >
                      <span
                        v-else-if="
                          item.statusMessage ===
                          'ยังไม่พร้อมใช้งาน ต้องตั้งค่า Account ขนส่งเพิ่มเติม'
                        "
                        ><v-icon size="10" color="error"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        ยังไม่พร้อมใช้งาน<br />ต้องตั้งค่า Account ขนส่งเพิ่มเติม</span
                      >
                      <span
                        v-else-if="
                          item.statusMessage === 'พร้อมใช้งานแล้วแต่ยังไม่เปิดใช้'
                        "
                        ><v-icon size="10" color="yellow"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        พร้อมใช้งาน<br />แต่ยังไม่เปิดใช้</span
                      >
                      <span v-else-if="item.statusMessage === 'ยังไม่พร้อมใช้งาน'"
                        ><v-icon size="10" color="error"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        item.statusMessage</span
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.Edit="{ item }">
                  <a
                    v-if="
                      item.expressCompanyId != 0 &&
                      item.expressCompanyId != 8 &&
                      item.expressCompanyId != 9 &&
                      item.expressCompanyId != 15 &&
                      item.expressCompanyId != 16
                    "
                    @click="handleOpenDialogEditExpress(item, true)"
                    style="display: flex; flex-direction: column"
                  >
                    <v-icon color="orange darken-3" size="18">fa-edit</v-icon>
                  </a>
                </template>
                <template v-slot:item.Delete="{ item }">
                  <a
                    v-if="item.isAllowDelete"
                    @click="handleDeleteMyExpreesClick(item.id)"
                    ><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <!-- ราคาขนส่ง -->
    <v-dialog v-model="my_express.dialog" width="80%" scrollable persistent>
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>ราคาค่าขนส่ง</span>
          <img :src="my_express.expressLogoDialog" width="100px" />
          <v-spacer />
          <v-btn icon @click="my_express.dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="formdata" lazy-validation>
            <v-data-table
              :headers="deliveryPrice.headers"
              :items="sortItemDeliveryPrice"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table"
            >
              <template
                v-for="(header, header_key) in deliveryPrice.headers"
                v-slot:[`header.${header.value}`]="{ header }"
              >
                <v-tooltip :key="header_key" v-slot:activator="{ on }">
                  <v-row v-if="!header.price">
                    <v-col cols="12">
                      {{ header.text }}
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <v-row align="center" justify="center" class="PB-B">
                        <a @click="handleOPPProvinceDialog(true, header)">
                          {{ header.text }} <v-icon>mdi-eye-outline</v-icon></a
                        >
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          v-for="(i, i_key) in header.price"
                          :key="i_key"
                          :class="i_key == 0 ? true : 'PB-L'"
                        >
                          <v-row align="center" justify="center">
                            {{ i.text }}
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tooltip>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td
                    v-for="(col, columnIndex) in deliveryPrice.headers"
                    :key="columnIndex"
                    class="text-center"
                  >
                    <span v-if="col.text == 'น้ำหนัก' && item.items[0].id !== 0">{{
                      item[col.value]
                    }}</span>
                    <v-text-field
                      v-else-if="col.text == 'น้ำหนัก' && item.items[0].id === 0"
                      :value="item.weight"
                      @change="(value) => (item.weight = value)"
                      autocomplete="off"
                      outlined
                      :rules="[(v) => !!v || 'กรุณาระบุ']"
                      hide-details
                      @keypress="isNumberWithDot($event)"
                      class="packhai-card-padding center-input pa-0"
                    ></v-text-field>
                    <span v-else-if="col.text == 'ขนาด' && item.items[0].id !== 0">{{
                      item.size
                    }}</span>
                    <v-text-field
                      v-else-if="col.text == 'ขนาด' && item.items[0].id === 0"
                      :value="item.size"
                      @change="(value) => (item.size = value)"
                      autocomplete="off"
                      outlined
                      hide-details
                      @keypress="isNumberWithDot($event)"
                      class="packhai-card-padding center-input pa-0"
                    ></v-text-field>
                    <div>
                      <span v-if="col.price">
                        <span v-for="(data, itemIndex) in item.items" :key="itemIndex">
                          <v-row v-if="col.provinceGroupId == data.provinceGroupID">
                            <v-col cols="6">
                              <v-text-field
                                :value="data.price"
                                @change="(value) => (data.price = value)"
                                :rules="[(v) => !!v || 'กรุณาระบุ']"
                                autocomplete="off"
                                outlined
                                hide-details
                                :disabled="!alldata.isPriceEditAble"
                                @keypress="isNumberWithDot($event)"
                                class="center-input"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :value="data.costPrice"
                                @change="(value) => (data.costPrice = value)"
                                autocomplete="off"
                                outlined
                                hide-details
                                :disabled="!alldata.isCostPriceEditAble"
                                @keypress="isNumberWithDot($event)"
                                class="center-input"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <a
                      @click="
                        RemoveDeliveryPrice(deliveryPrice, deliveryPrice.indexOf(item))
                      "
                    >
                      <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                    </a>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <tr class="pt-5">
              <td cols="12">
                <v-btn
                  v-if="deliveryPrice.length != 0"
                  :elevation="1"
                  color="primary"
                  class="pa-2"
                  @click="AddDeliveryPrice(deliveryPrice)"
                >
                  <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                  เพิ่ม
                </v-btn>
              </td>
            </tr>
          </v-form>
        </v-card-text>
        <v-divider />

        <v-row row class="height-57 ml-0 mr-0 pa-2">
          <v-col cols="12" md="9">
            <v-checkbox
              v-if="deliveryPrice.length != 0"
              v-model="isUpdateAllToShop"
              hide-details
              color="info"
              label="อัพเดทราคาไปยังทุกร้านค้า"
              style="align-items: center"
            ></v-checkbox>
          </v-col>
          <v-col class="" cols="12" md="3" align="right">
            <v-btn
              v-if="deliveryPrice.length != 0"
              :elevation="1"
              color="primary"
              @click="handleUpdateDeliveryPriceClick()"
            >
              <v-icon size="20">mdi-content-save</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- เพิ่มกลุ่มจังหวัด -->
    <v-dialog
      v-model="my_express.dialogProvince"
      scrollable
      width="515"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3" class="mr-3">mdi-group</v-icon>
          กลุ่มจังหวัด
          <v-spacer />
          <v-btn icon @click="my_express.dialogProvince = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="oppdialog" lazy-validation>
            <v-row>
              <v-col cols="3"> ชื่อกลุ่มจังหวัด: </v-col>
              <v-col cols="9">
                <v-text-field
                  :value="provinceGroup.description"
                  @change="(value) => (provinceGroup.description = Trim_value(value))"
                  ref="provinceGroupdescription"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pt-2"> เลือกจังหวัด: {{ total }} </v-col>
              <v-col cols="9" class="pt-2">
                <v-row class="select-province ma-0">
                  <v-col
                    cols="12"
                    id="scroll-target"
                    class="overflow-y-auto pa-2 height-515"
                  >
                    <v-checkbox
                      v-for="i in province"
                      v-model="selectProvince"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                      :disabled="i.disabled"
                      color="info"
                      class="mr-3"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ตั้งค่าการแพ็ค -->
    <v-dialog v-model="packsetting.packdialog" width="80%" scrollable persistent>
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <!-- <v-icon size="30" color="red" class="mr-1">mdi-package-variant-closed</v-icon> -->
          ตั้งค่าค่าบริการแพ็คสินค้า
          <img :src="my_express.expressLogoDialog" width="100px" />
          <v-spacer />
          <v-btn icon @click="packsetting.packdialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />

        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="DialogPackForm" lazy-validation>
            <v-card-text class="pt-0">
              <v-row row class="packhai-card-padding">
                <v-radio-group row wrap v-model="packsetting.packing">
                  <v-radio
                    v-for="i in packsetting.packing_List"
                    color="info"
                    v-bind:key="i['value']"
                    :label="i['label']"
                    :value="i['value']"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-row
                v-if="[3, 4].includes(packsetting.packing)"
                row
                wrap
                class="delivery-600"
              >
                <v-row
                  v-for="i in packsetting.priceAsClass"
                  :key="i.id"
                  row
                  wrap
                  class="packhai-card-padding py-1"
                >
                  <v-col cols="4">
                    <v-text-field
                      :value="i.start"
                      @change="(value) => (i.start = value)"
                      autocomplete="off"
                      label="ชิ้นที่"
                      outlined
                      @keypress="isNumberWNoDot($event)"
                      disabled
                      hide-details
                      style="width: 200px"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="i.end"
                      label="ถึง"
                      :ref="'refEnd' + i.id"
                      outlined
                      autocomplete="off"
                      @keypress="isNumberWNoDot($event)"
                      @change="
                        ChangeFollowPices(
                          packsetting.priceAsClass,
                          packsetting.priceAsClass.indexOf(i)
                        )
                      "
                      hide-details
                      style="width: 200px"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :value="i.price"
                      @change="(value) => (i.price = value)"
                      autocomplete="off"
                      label="ราคา"
                      :rules="[(v) => (v !== '' && v !== null) || 'กรุณาระบุ']"
                      outlined
                      hide-details
                      style="width: 200px"
                      @keypress="isNumberWithDot($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-row>

              <v-row row wrap>
                <v-col cols="12">
                  <v-data-table
                    :headers="CheckPackingHeader(packsetting.packing)"
                    :items="packsetting.postboxlist"
                    :items-per-page="9999999999"
                    item-key="key"
                    hide-default-footer
                  >
                    <template v-slot:item.orderNo="{ item }">
                      <v-text-field
                        v-model="item.orderNo"
                        @change="
                          SetOrderNo(
                            packsetting.postboxlist,
                            packsetting.postboxlist.indexOf(item)
                          )
                        "
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.name }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.name"
                        @change="(value) => (item.name = value)"
                        :rules="[(v) => CheckEmpty(v) || 'กรุณาระบุ']"
                        autocomplete="off"
                        hide-details
                        outlined
                        class="packhai-card-padding pa-0"
                      />
                    </template>
                    <template v-slot:item.cubic1="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.cubic1 }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.cubic1"
                        @change="(value) => (item.cubic1 = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.cubic2="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.cubic2 }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.cubic2"
                        @change="(value) => (item.cubic2 = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.cubic3="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.cubic3 }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.cubic3"
                        @change="(value) => (item.cubic3 = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.boxCostPrice="{ item }">
                      <v-text-field
                        :value="item.boxCostPrice"
                        @change="
                          (value) => (item.boxCostPrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.boxSellPrice="{ item }">
                      <v-text-field
                        :value="item.boxSellPrice"
                        @change="
                          (value) => (item.boxSellPrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.packPrice="{ item }">
                      <v-text-field
                        :value="item.packPrice"
                        @change="(value) => (item.packPrice = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.packAndBoxPrice="{ item }">
                      <v-text-field
                        :value="item.packAndBoxPrice"
                        @change="
                          (value) => (item.packAndBoxPrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.bubblePrice="{ item }">
                      <v-text-field
                        :value="item.bubblePrice"
                        @change="
                          (value) => (item.bubblePrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.delete="{ item }">
                      <a
                        @click="
                          RemovePostBox(
                            packsetting.postboxlist,
                            packsetting.postboxlist.indexOf(item)
                          )
                        "
                      >
                        <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                      </a>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="pt-5">
                <v-col cols="12">
                  <v-btn
                    :elevation="1"
                    color="primary"
                    class="pa-2"
                    @click="AddPostBox(packsetting.postboxlist)"
                  >
                    <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                    เพิ่ม
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-row row class="height-57 mr-0 ml-0 pa-2">
          <v-col cols="12" md="9">
            <v-checkbox
              v-model="isUpdateAllToShop"
              hide-details
              color="info"
              label="อัพเดทราคาไปยังทุกร้านค้า"
              style="align-items: center"
            ></v-checkbox>
          </v-col>
          <v-col class="" cols="12" md="3" align="right">
            <v-btn :elevation="1" color="primary" @click="handleUpdatePackSettingClick()">
              <v-icon size="20">mdi-content-save</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- แก้ไขหรือเพิ่มขนส่งของฉัน -->
    <v-dialog
      v-model="my_express_edit.dialog"
      scrollable
      max-width="70%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <!-- this.my_express.expressLogoDialog  -->
          <v-icon size="24" color="success">mdi-truck-fast</v-icon>
          <span v-if="!my_express_edit.isUpdate" class="ml-3">เพิ่มขนส่ง</span>
          <span v-else class="ml-3">แก้ไขขนส่ง</span>
          <v-spacer />
          <v-btn icon @click="my_express_edit.dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="formAddExpress" lazy-validation>
            <v-row>
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ขนส่ง : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-if="!my_express_edit.isUpdate"
                  v-model="my_express_edit.express_selected"
                  :items="my_express_edit.express_data_list"
                  item-text="name"
                  item-value="id"
                  ref="refExpressAddbranchID"
                  :rules="my_express_edit.rulesExpress"
                  @change="ExpressCompanyChanged()"
                  required
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                >
                  <template slot="selection" slot-scope="data">
                    <img
                      v-if="data.item.logo != null"
                      :src="data.item.logo"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <img
                      v-if="data.item.logo != null"
                      :src="data.item.logo"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ data.item.name }}
                  </template>
                </v-select>
                <span v-else-if="my_express_edit.express_selected != null">
                  <img :src="my_express_edit.express_selected.logo" width="100px" />
                  {{ my_express_edit.express_selected.name }}
                </span>
                <span
                  v-if="
                    my_express_edit.expressValidateText !== null ||
                    my_express_edit.expressValidateText !== ''
                  "
                  style="color: red"
                  >{{ my_express_edit.expressValidateText }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ชื่อ : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="my_express_edit.BranchExpressName"
                  @change="(value) => (my_express_edit.BranchExpressName = value)"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              v-show="my_express_edit.express_selected != null && my_express_edit.showCOD"
            >
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> Account : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  ref="refAccountValidate"
                  :rules="my_express_edit.rulesAccount"
                  v-model="my_express_edit.account_list_selected"
                  :items="my_express_edit.account_list"
                  item-text="shortName"
                  @change="(value) => SetDataLogicticAccountForadd(value)"
                  item-value="id"
                  :disabled="my_express_edit.isUpdate || my_express_edit.isOasysAccount"
                  required
                  outlined
                  hide-details
                  return-object
                  dense
                ></v-select>
                <span
                  v-if="my_express_edit.account_list_selected === null"
                  style="color: red"
                  >{{ my_express_edit.accountValidateText }}</span
                >
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pt-4 pb-2"
                align="left"
                v-if="
                  !my_express_edit.isUpdate &&
                  my_express_edit.express_selected != null &&
                  my_express_edit.express_selected.id != -1 &&
                  !my_express_edit.isOasysAccount
                "
              >
                <a @click="handleShowDialogAddAccount()">
                  <span class="fn-14"> เพิ่ม acount ใหม่ </span>
                </a>
              </v-col>
            </v-row>

            <v-row v-if="my_express_edit.showCOD">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ต้นทุน COD : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="my_express_edit.costCod"
                  @change="(value) => (my_express_edit.costCod = value)"
                  :disabled="!my_express_edit.isCostPriceEditAble"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  @keypress="isNumberWithDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="my_express_edit.showCOD">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ราคาขาย COD : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="my_express_edit.CodPrice"
                  @change="(value) => (my_express_edit.CodPrice = value)"
                  autocomplete="off"
                  ref="refCodPersentAddbranchID"
                  outlined
                  :disabled="!my_express_edit.isPriceEditAble"
                  :rules="my_express_edit.rulesCodPersent"
                  required
                  dense
                  hide-details
                  @keypress="isNumberWithDot($event)"
                ></v-text-field>
                <span
                  v-if="
                    my_express_edit.CodPrice === null || my_express_edit.CodPrice === ''
                  "
                  style="color: red"
                  >{{ my_express_edit.codPersentValidateText }}</span
                >
              </v-col>
            </v-row>

            <!-- below code inter Express only  -->
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ServiceCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.serviceCodeID"
                  :items="interExpressOption.serviceCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesServiceCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ServiceControlCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.serviceControlCodeID"
                  :items="interExpressOption.serviceControlCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesServiceControlCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> TemperatureControlCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.temperatureControlCodeID"
                  :items="interExpressOption.temperatureControlCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesTemperatureControlCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> TemperatureTypeCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.temperatureTypeCodeID"
                  :items="interExpressOption.temperatureTypeCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesTemperatureTypeCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="my_express_edit.showCOD">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> COD : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-3 pb-2">
                <v-radio-group v-model="my_express_edit.isCod" row>
                  <v-radio label="เปิด" :value="true"></v-radio>
                  <v-radio label="ไม่เปิด" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row row>
            <v-col cols="12" md="9" align="right">
              <v-checkbox
                v-model="isUpdateAllToShop"
                hide-details
                color="info"
                label="อัพเดทข้อมูลไปยังทุกร้านค้า"
                style="align-items: center"
              ></v-checkbox>
            </v-col>
            <v-col class="" cols="12" md="3" align="right">
              <v-btn
                :elevation="1"
                color="primary"
                @click="handleUpdateBranchExpressClick()"
              >
                <v-icon size="20">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ผู้ใช้ -->
    <v-dialog
      v-model="my_express_edit.accountDIalog"
      scrollable
      max-width="50%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>เพิ่ม account ขนส่ง </span>
          <v-spacer />
          <v-btn icon @click="my_express_edit.accountDIalog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <v-form ref="DialogAddLogisticAccountForm" lazy-validation>
            <!-- <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3>  Shippop? : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-radio-group
                  v-model="dialog_data.isShippop"
                  row
                >
                  <v-radio
                    label="No"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="Yes"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3> การจ่ายเงิน : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-radio-group
                  v-model="dialog_data.isOMS"
                  row
                  @change="change_title()"
                >
                  <v-radio
                    label="จ่ายเงิน PACKHAI HQ"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="จ่ายเงินกับขนส่งโดยตรง"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
            <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3>ขนส่ง :</h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <span v-if="my_express_edit.express_selected != null">
                  <img :src="my_express_edit.express_selected.logo" width="100px" />
                  {{ my_express_edit.express_selected.name }}
                </span>
              </v-col>
            </v-row>

            <v-row row wrap class="py-0">
              <v-col cols="12" md="3" class="pb-2" v-if="LoginEmail_or_ViP_ID_IsShow">
                <h4 v-if="vip_id_title == null">ViP_ID :</h4>
                <h4 v-else>{{ vip_id_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2" v-if="LoginEmail_or_ViP_ID_IsShow">
                <v-text-field
                  v-model="dialog_data.viP_ID"
                  autocomplete="off"
                  ref="viP_ID"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pb-2" v-if="Key_or_ViP_Password_IsShow">
                <h4 v-if="vip_password_title == null">ViP_Password :</h4>
                <h4 v-else>{{ vip_password_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2" v-if="Key_or_ViP_Password_IsShow">
                <v-text-field
                  v-model="dialog_data.viP_Password"
                  autocomplete="off"
                  ref="viP_Password"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              row
              wrap
              class="py-0"
              v-if="!dialog_data.isShippop && CustomerID_IsShow"
            >
              <v-col cols="12" md="3">
                <h4 v-if="customer_id_title == null">CustomerID :</h4>
                <h4 v-else>{{ customer_id_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-text-field
                  v-model="dialog_data.customerID"
                  autocomplete="off"
                  ref="refCustomerID"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row row wrap class="py-0">
              <v-col cols="12" md="12" align="right">
                <v-btn dark color="primary" class="mr-1" @click="AddEditAccount()">
                  <v-icon left>mdi-content-save</v-icon>
                  บันทึก
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import { logisticService_dotnet, shopService_dotnet } from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  FormatInvoice,
  FormatOrder,
  formatMoney,
  isNumberWNoDot,
  set_format_date_time,
  format_price,
  format_vat_type,
  isNumberWithDot,
  Trim_value,
  Trim_rules,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    page_loading: true,
    loading: false,

    my_express: {
      headers: [
        { text: "ลำดับ", align: "center", sortable: false, value: "no", width: "50px" },
        { text: "แก้ไข", align: "center", sortable: false, value: "Edit", width: "50px" },
        {
          text: "ตั้งค่าการแพ็ค",
          align: "center",
          sortable: false,
          value: "viewDialogPackSetting",
          width: "140px",
        },
        {
          text: "ดูค่าขนส่ง",
          align: "center",
          sortable: false,
          value: "viewDialogMyexpress",
          width: "140px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "120px",
        },
        {
          text: "Account",
          align: "left",
          sortable: false,
          value: "Name",
          width: "200px",
        },
        {
          text: "ชื่อขนส่ง",
          align: "left",
          sortable: false,
          value: "branchExpressName",
          width: "130px",
        },
        { text: "COD", align: "left", sortable: false, value: "COD", width: "50px" },
        {
          text: "ราคาขาย COD",
          align: "left",
          sortable: false,
          value: "codpercent",
          width: "100px",
        },
        {
          text: "ต้นทุน COD",
          align: "left",
          sortable: false,
          value: "codcost",
          width: "50px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusMessage",
          width: "170px",
        },
        {
          text: "เปิดใช้งาน",
          align: "left",
          sortable: false,
          value: "isActive",
          width: "80px",
        },
        { text: "ลบ", align: "left", sortable: false, value: "Delete", width: "50px" },
      ],
      dataset: [],
      dialog: false,
      expressLogoDialog: "",
      filterDialogShow: "",
      BranchExpressId: null,
      dialogProvince: false,
      isUpdate: false,
    },
    my_express_edit: {
      dialog: false,
      isUpdate: false,
      Logo: null,
      express_selected: null,
      express_data_list: null,
      accountID: null,
      account_list: [],
      isCod: true,
      costCod: null,
      CodPrice: null,
      Hqaccount: null,
      isOasysAccount: null,
      dataAccount_list: [],
      isPriceEditAble: false,
      isCostPriceEditAble: null,
      accountDIalog: false,
      showCOD: false,
      rulesExpress: [],
      rulesCodPersent: [],
      rulesAccount: [],
      accountValidateText: "",
      expressValidateText: "",
      codPersentValidateText: "",
    },
    interExpressOption: {
      showHide: false,
      serviceCodeID: null,
      rulesServiceCode: [],
      rulesServiceControlCode: [],
      rulesTemperatureControlCode: [],
      rulesTemperatureTypeCode: [],
      serviceCodeValidateText: "",
      serviceControlCodeValidateText: "",
      temperatureControlCodeValidateText: "",
      temperatureTypeCodeValidateText: "",
      serviceCode: [
        { id: "ส่งด่วน", value: "ส่งด่วน" },
        { id: "ส่งด่วนภายในวัน", value: "ส่งด่วนภายในวัน" },
        { id: "ส่งธรรมดา", value: "ส่งธรรมดา" },
      ],
      serviceControlCodeID: null,
      serviceControlCode: [
        { id: "บริการขนส่งสินค้าทั่วไป", value: "บริการขนส่งสินค้าทั่วไป" },
        { id: "บริการขนส่งผัก-ผลไม้", value: "บริการขนส่งผัก-ผลไม้" },
        { id: "บริการขนส่งกล่องโฟม", value: "บริการขนส่งกล่องโฟม" },
        { id: "บริการแพ็คพร้อมส่ง", value: "บริการแพ็คพร้อมส่ง" },
        { id: "บริการขนส่งกล่องกระดาษเย็น", value: "บริการขนส่งกล่องกระดาษเย็น" },
      ],
      temperatureControlCodeID: null,
      temperatureControlCode: [
        { id: "สินค้าทั่วไป", value: "สินค้าทั่วไป" },
        { id: "สินค้าควบคุมอุณหภูมิ", value: "สินค้าควบคุมอุณหภูมิ" },
      ],
      temperatureTypeCodeID: null,
      temperatureTypeCode: [
        {
          id: "สินค้าควบคุมอุณหภูมิที่ 2-8 องศา",
          value: "สินค้าควบคุมอุณหภูมิที่ 2-8 องศา",
        },
        {
          id: "สินค้าควบคุมอุณหภูมิที่ต่ำกว่า -15 องศา",
          value: "สินค้าควบคุมอุณหภูมิที่ต่ำกว่า -15 องศา",
        },
        { id: "ขนส่งสินค้าทั่วไป", value: "ขนส่งสินค้าทั่วไป" },
        {
          id: "สินค้าควบคุมอุณหภูมิที่ 15 - 25 องศา",
          value: "สินค้าควบคุมอุณหภูมิที่ 15 - 25 องศา",
        },
      ],
    },
    alldata: [],
    deliveryPrice: [],
    removeDeliveryPrice: [],
    isUpdateAllToShop: false,

    Express_data_with_accountList: [],
    // province
    total: 0,
    provinceIdList: [],
    selectProvince: [],
    province: [],
    provinceTemp: [],
    provinceGroupItem: [],
    provinceGroup: [],
    BranchID:parseInt(localStorage.getItem("Branch_BranchID")),
    // packsetting
    packsetting: {
      packdialog: false,
      postboxlist: [],
      packing: 3,
      packing_List: [
        { label: "ตามจำนวนชิ้น", value: 3 },
        { label: "ตาม SKU", value: 4 },
        { label: "ตามขนาดกล่อง", value: 2 },
        { label: "คงที่", value: 1 },
      ],
      priceAsClass: [
        { id: 0, start: 1, end: "1", price: 0 },
        { id: 1, start: 2, end: "", price: 3 },
      ],
      removeItem: [],
    },

    //dialog เพิ่ม account
    dialog_data: {
      id: 0,
      expressCompanyID: null,
      expressCompanyList: null,
      shopID: null,
      shopList: null,
      isShippop: false,
      viP_ID: null,
      viP_Password: null,
      customerID: null,
      isOMS: true,
      isNeedCollectDeliveryPrice: false,
      isPickup: false,
    },
    LoginEmail_or_ViP_ID_IsShow: true,
    Key_or_ViP_Password_IsShow: true,
    CustomerID_IsShow: true,
    vip_id_title: null,
    vip_password_title: null,
    customer_id_title: null,
  }),
  watch: {
    selectProvince() {
      var check = false;
      this.total = 0;
      this.provinceIdList = [];
      for (var i = 0; i < this.province.length; i++) {
        if (!this.province[i].disabled) {
          check = this.selectProvince.includes(this.province[i].id);
          if (check) {
            this.total += 1;
            this.provinceIdList.push(this.province[i].id);
          }
        }
      }
    },
    "my_express_edit.accountDIalog": {
      handler(value) {
        if (!value) {
          if (this.my_express_edit.isUpdate) {
            this.my_express_edit.dialog = true;
          } else {
            this.handleOpenDialogEditExpress(null, false);
            // this.get_branch_express_for_add()
            // this.my_express_edit.dialog = true;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    sortItemDeliveryPrice() {
      return [...this.deliveryPrice].sort((a, b) => {
        if (a.weight === "" || b.weight === "") {
          return 0;
        }
        return a.weight - b.weight;
      });
    },
  },
  async created() {
    await this.get_branch_express();
    this.page_loading = false;
    // this.checklog();
  },
  methods: {
    async get_branch_express() {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-branch-express-list?BranchID=" +
          this.BranchID,
        { headers: header_token }
      );
      this.loading = false;
      if (response.data.status === "success") {
        this.my_express.dataset = response.data.branchExpressList;
      }
    },
    async get_branch_express_for_add() {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-branch-express-for-add?BranchID=" +
          this.BranchID,
        { headers: header_token }
      );
      this.loading = false;
      if (response.data.status === "success") {
        this.Express_data_with_accountList = response.data.expressList;
        return response.data.expressList;
      }
    },

    async get_branch_express_delivery_price(expressBId) {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-branch-express-delivery-price?BranchExpressID=" +
          expressBId,
        { headers: header_token }
      );
      this.loading = true;
      if (response.data.status === "success") {
        this.loading = false;
        this.my_express.BranchExpressId = expressBId;
        this.alldata = response.data;
        this.deliveryPrice = response.data.deliveryPrices;
        this.provinceGroup = response.data.provinceGroupList;
        this.provinceGroupItem = response.data.provinceGroupItemList;
        await this.SetDeliveryPrice();
      }
    },
    async get_branch_express_postbox(expressBId) {
      this.my_express.BranchExpressId = expressBId;
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-branch-express-postbox?BranchExpressID=" +
          expressBId,
        { headers: header_token }
      );
      this.loading = false;
      if (response.data.status === "success") {
        this.packsetting.postboxlist = response.data.postboxList;
        this.packsetting.packing = response.data.packingChargedType;
        this.packsetting.priceAsClass =
          response.data.packingStep === null || response.data.packingStep.length === 0
            ? [
                { id: 0, start: 1, end: "1", price: 0, no: 1 },
                { id: 1, start: 2, end: "", price: 3, no: 2 },
              ]
            : response.data.packingStep;
      }
    },
    async get_branch_express_list() {
      let response = await axios.post(
        logisticService_dotnet + "Logistic/get-express-list",
        {
          branchId: this.BranchID,
        },
        { headers: header_token }
      );
      this.loading = false;
      if (response.status === 200) {
        return response.data;
      }
    },
    async get_logistic_account_edit(expressBId) {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-logistic-account-for-edit?BranchID=" +
          this.BranchID +
          "&BranchExpressID=" +
          expressBId,
        { headers: header_token }
      );

      if (response.data.status === "success") {
        this.my_express_edit.account_list = [];
        this.my_express_edit.account_list_selected = null;
        for (var x in response.data.accountList) {
          this.my_express_edit.account_list.push(response.data.accountList[x]);
        }
      }
    },
    async handleUpdateIsActive(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ยืนยันการเลือก",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.post(
            logisticService_dotnet + "BranchExpress/update-active-branch-express",
            {
              branchExpressID: item.id,
              isActive: !item.isActive,
            },
            { headers: header_token }
          );
          this.loading = false;
          if (response.data.status === "success") {
            await this.get_branch_express();
          }
        }
      });
    },
    async handleDeleteMyExpreesClick(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ยืนยันการลบขนส่งนี้ใช่ไหม",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.get(
            logisticService_dotnet +
              "BranchExpress/delete-branch-express?BranchExpressID=" +
              item,
            { headers: header_token }
          );
          this.loading = false;
          if (response.data.status === "success") {
            await this.get_branch_express();
          }
        }
      });
    },
    async handleOpenDialogExpress(item, type) {
      this.isUpdateAllToShop = false;
      this.my_express.expressLogoDialog = item.expressCompanyLogo;
      if (type === 1) {
        this.my_express.dialog = true;
        this.deliveryPrice = [];
        this.removeDeliveryPrice = [];
        await this.get_branch_express_delivery_price(item.id);
      } else if (type === 2) {
        this.packsetting.packdialog = true;
        this.packsetting.removeItem = [];
        await this.get_branch_express_postbox(item.id);
      }
    },
    async handleOpenDialogEditExpress(item, isUpdate) {
      this.my_express_edit.isUpdate = isUpdate;
      this.isUpdateAllToShop = false;
      await this.ClearRules();

      if (isUpdate) {
        this.my_express_edit.BranchExpressName = item.branchExpressName;
        if (
          this.my_express_edit.BranchExpressName == null ||
          this.my_express_edit.BranchExpressName == ""
        ) {
          this.my_express_edit.BranchExpressName = item.expressCompanyName;
        }
        this.my_express_edit.express_selected = null;
        this.my_express_edit.express_selected = {
          id: item.expressCompanyId,
          name: item.expressCompanyName,
          logo: item.expressCompanyLogo,
        };

        this.my_express_edit.express_data_list = [];
        this.my_express_edit.express_data_list.push({
          id: item.expressCompanyId,
          name: item.expressCompanyName,
          logo: item.expressCompanyLogo,
        });
        this.my_express_edit.accountName = item.logisticAccountShortName;
        (this.my_express_edit.costCod = await item.codcost), //ใส่await ไว้ตอนเพิ่มแล้วมากดแก้ไข ค่าเปลี่ยนช้าทำให้แสดงเป็นnull
          (this.my_express_edit.CodPrice = await item.codpercent);

        this.my_express_edit.isOasysAccount = item.isOasysAccount;
        this.my_express.BranchExpressId = item.id;
        this.my_express_edit.showCOD = true;
        this.my_express_edit.isCod = item.isAllowCod;
        this.my_express_edit.isCostPriceEditAble = item.isCostPriceEditAble;
        this.my_express_edit.isPriceEditAble = item.isPriceEditAble;
        // interexpress only
        this.interExpressOption.showHide = item.expressCompanyId === 18 ? true : false;
        this.interExpressOption.serviceCodeID = item.servicecode;
        this.interExpressOption.serviceControlCodeID = item.serviceControlCode;
        this.interExpressOption.temperatureControlCodeID = item.temperatureControlCode;
        this.interExpressOption.temperatureTypeCodeID = item.temperatureTypeCode;

        await this.get_logistic_account_edit(item.id);
        if (item.branchLogisticAccountID != null) {
          var acc = this.my_express_edit.account_list.filter(
            (x) => x.id == item.branchLogisticAccountID
          )[0];

          this.my_express_edit.account_list_selected = acc;
        } else {
          var acc1 = this.my_express_edit.account_list.filter(
            (x) => x.id == item.hqLogisticAccountID
          )[0];
          this.my_express_edit.account_list_selected = acc1;
        }
      } else {
        let for_add = await this.get_branch_express_for_add();
        this.my_express_edit.isCostPriceEditAble = null;
        this.my_express_edit.isPriceEditAble = true;
        this.my_express_edit.BranchExpressName = null;
        this.my_express_edit.accountName = null;
        (this.my_express_edit.costCod = null), (this.my_express_edit.CodPrice = null);
        this.my_express_edit.Logo = null;
        this.my_express_edit.account_list = [];
        this.my_express_edit.isCod = true;
        this.my_express_edit.account_list_selected = null;
        this.my_express_edit.isOasysAccount = null;
        this.my_express.BranchExpressId = null;
        this.interExpressOption.serviceCodeID = null;
        this.interExpressOption.serviceControlCodeID = null;
        this.interExpressOption.temperatureControlCodeID = null;
        this.interExpressOption.temperatureTypeCodeID = null;
        this.interExpressOption.showHide = false;
        this.my_express_edit.showCOD = true;

        //ยัดexpress เข้า dropdown
        this.my_express_edit.express_selected = {
          id: -1,
          name: "--ทั้งหมด--",
          logo: null,
          branchID:-1
        }; //เปลี่ยนเป็น30 เพราะว่า expresscompany =0 มันคือรับสินค้าด้วยตัวเอง
        this.my_express_edit.express_data_list = [
          { id: -1, name: "--ทั้งหมด--", logo: null,branchID:-1 },
        ];
        for (var x in for_add) {
          this.my_express_edit.express_data_list.push({
            id: for_add[x].expressCompanyID,
            name: for_add[x].expressCompanyName,
            logo: for_add[x].expressCompanyLogo,
            branchID:for_add[x].branchID
          });
        }
      }
      this.my_express_edit.dialog = true;
    },
    async ExpressCompanyChanged() {
      var expressCompanyID = this.my_express_edit.express_selected.id;

      (this.my_express_edit.costCod = null), (this.my_express_edit.CodPrice = null);
      this.my_express_edit.expressValidateText =
        expressCompanyID === -1 ? this.my_express_edit.expressValidateText : "";

      //inter express only
      this.interExpressOption.showHide = expressCompanyID === 18 ? true : false;
      //3party express showCOD
      this.my_express_edit.showCOD = ![0, 8, 9, 15].includes(expressCompanyID); // ให้show COD เฉพาะขนส่งที่ไม่ได้มาจาก marketplace

      this.my_express_edit.BranchExpressName =
        expressCompanyID === -1 ? null : this.my_express_edit.express_selected["name"];

      this.my_express_edit.account_list = [];
      this.my_express_edit.account_list_selected = null;

      var item = this.Express_data_with_accountList.filter(
        (x) => x.expressCompanyID == expressCompanyID
      );

      if (item != null && item.length > 0) {
        for (var x in item[0].accountList) {
          this.my_express_edit.account_list.push(item[0].accountList[x]);
        }

        this.my_express_edit.dataAccount_list = item[0].accountList;
      }
    },
    async handleUpdateBranchExpressClick() {
      if (this.my_express_edit.express_selected.id === -1) {
        this.my_express_edit.rulesExpress = [(v) => (v && v.id !== -1) || "กรุณาระบุ"];
        this.$refs.refExpressAddbranchID.focus();
        this.my_express_edit.expressValidateText = "กรุณาเลือกขนส่ง";
        // this.$refs.formAddExpress.validate()
        return;
      } else {
        console.log(this.my_express_edit.express_selected);
        
        if (![0, 8, 9, 15].includes(this.my_express_edit.express_selected.id) && this.my_express_edit.express_selected.branchID==0) {
       
          
          if (
            this.my_express_edit.CodPrice === null ||
            this.my_express_edit.CodPrice === ""
          ) {
            this.my_express_edit.rulesCodPersent = [(v) => !!v || "กรุณาระบุ"];
            this.$refs.refCodPersentAddbranchID.focus();
            this.my_express_edit.codPersentValidateText = "กรุณาระบุราคาขาย COD";
            // this.$refs.formAddExpress.validate()
            return;
          }
        }

        if (this.my_express_edit.express_selected.id === 18) {
          await this.SetRulesInterExpress();
          if (
            this.interExpressOption.serviceCodeID === null ||
            this.interExpressOption.serviceCodeID === ""
          ) {
            this.$refs.formAddExpress.validate();
            return;
          } else if (
            this.interExpressOption.serviceControlCodeID === null ||
            this.interExpressOption.serviceControlCodeID === ""
          ) {
            this.$refs.formAddExpress.validate();
            return;
          } else if (
            this.interExpressOption.temperatureControlCodeID === null ||
            this.interExpressOption.temperatureControlCodeID === ""
          ) {
            this.$refs.formAddExpress.validate();
            return;
          } else if (
            this.interExpressOption.temperatureTypeCodeID === null ||
            this.interExpressOption.temperatureTypeCodeID === ""
          ) {
            this.$refs.formAddExpress.validate();
            return;
          }
        }
      }
      this.loading = true;
      let response = await axios.post(
        logisticService_dotnet + "BranchExpress/update-branch-express",
        {
          id: this.my_express_edit.isUpdate ? this.my_express.BranchExpressId : 0,
          branchExpressName: this.my_express_edit.BranchExpressName,
          branchID: this.BranchID,
          expressCompanyID: this.my_express_edit.express_selected.id,
          isAllowCOD: this.my_express_edit.isCod,
          branchLogisticAccountID:this.my_express_edit.account_list_selected==null? null: this.my_express_edit.account_list_selected.branchLogisticAccountID,
          hqLogisticAccountID:this.my_express_edit.account_list_selected==null? null: this.my_express_edit.account_list_selected.hqLogisticAccountID,
          codPercent: this.my_express_edit.CodPrice,
          codCost: this.my_express_edit.costCod,
          isUpdateAllToShop: this.isUpdateAllToShop,
          serviceCode:
            this.my_express_edit.express_selected.id === 18
              ? this.interExpressOption.serviceCodeID
              : null,
          serviceControlCode:
            this.my_express_edit.express_selected.id === 18
              ? this.interExpressOption.serviceControlCodeID
              : null,
          temperatureControlCode:
            this.my_express_edit.express_selected.id === 18
              ? this.interExpressOption.temperatureControlCodeID
              : null,
          temperatureTypeCode:
            this.my_express_edit.express_selected.id === 18
              ? this.interExpressOption.temperatureTypeCodeID
              : null,
        },
        { headers: header_token }
      );
      if (response.data.status === "success") {
        this.loading = false;
        this.my_express_edit.dialog = false;
        await this.get_branch_express();
        this.AlertSuccess();
      } else {
        this.loading = false;
        this.AlertError();
      }
    },
    async handleUpdateDeliveryPriceClick() {
      if (this.$refs.formdata.validate()) {
        this.loading = true;
        for (var o in this.deliveryPrice) {
          for (var a in this.deliveryPrice[o].items) {
            this.deliveryPrice[o].items[a].costPrice =
              this.deliveryPrice[o].items[a].costPrice === ""
                ? null
                : this.deliveryPrice[o].items[a].costPrice;
          }
        }
        let response = await axios.post(
          logisticService_dotnet + "BranchExpress/update-branch-express-delivery-price",
          {
            branchExpressID: this.my_express.BranchExpressId,
            isUpdateAllToShop: this.isUpdateAllToShop,
            deliveryPrices: this.deliveryPrice,
            deletedIDList: this.removeDeliveryPrice,
          },
          { headers: header_token }
        );
        this.loading = false;
        if (response.data.status === "success") {
          this.AlertSuccess();
        } else {
          this.AlertError();
        }
      }
    },
    async handleOPPProvinceDialog(isUpdate, item) {
      // this.loading = true
      // this.rulesDescription()

      if (this.province.length === 0) {
        let province = await axios.post(
          logisticService_dotnet + "Logistic/get-province",
          {},
          { headers: header_token }
        );
        if (province.status === 200) {
          var data = [];
          for (var i in province.data) {
            var dataItem = province.data[i];
            dataItem["disabled"] = false;
            data.push(dataItem);
          }
          this.province = data;
          this.provinceTemp = data;
        }
      } else {
        this.province = this.provinceTemp;
      }

      this.my_express.isUpdate = false;
      this.total = 0;
      this.selectProvince = [];
      this.provinceGroup.id = 0;
      this.provinceGroup.description = null;
      this.provinceGroup.expressCompanyId = item.id;

      for (var j in this.provinceGroupItem) {
        this.selectProvince.push(this.provinceGroupItem[j].provinceId);
      }

      for (var k in this.province) {
        if (this.selectProvince.includes(this.province[k].id)) {
          this.province[k].disabled = true;
        }
      }

      if (isUpdate) {
        this.my_express.isUpdate = true;
        this.provinceGroup.id = item.provinceGroupId;
        this.provinceGroup.description = item.text;
        var selectProvince = [];
        for (var o in this.provinceGroupItem) {
          if (this.provinceGroupItem[o].provinceGroupId === item.provinceGroupId) {
            selectProvince.push(this.provinceGroupItem[o].provinceId);
          }
        }

        for (var l in this.province) {
          if (selectProvince.includes(this.province[l].id)) {
            this.province[l].disabled = false;
          }
        }

        this.total = selectProvince.length;
      }
      // this.loading = false
      this.my_express.dialogProvince = true;
    },
    async handleUpdatePackSettingClick() {
      if (this.$refs.DialogPackForm.validate()) {
        this.loading = true;
        let response = await axios.post(
          logisticService_dotnet + "BranchExpress/update-branch-express-postbox",
          {
            branchExpressID: this.my_express.BranchExpressId,
            isUpdateAllToShop: this.isUpdateAllToShop,
            packingChargedType: this.packsetting.packing,
            postboxList: this.packsetting.postboxlist,
            packingStep: this.packsetting.priceAsClass,
            deletedPostboxID: this.packsetting.removeItem,
          },
          { headers: header_token }
        );
        if (response.data.status === "success") {
          this.loading = false;
          await this.AlertSuccess();
          setTimeout(() => {
            this.packsetting.packdialog = false;
          }, 1500);
        } else {
          this.AlertError();
        }
      }
    },
    async handleShowDialogAddAccount() {
      (this.dialog_data = {
        id: 0,
        expressCompanyID: null,
        expressCompanyList: null,
        isShippop: false,
        viP_ID: null,
        viP_Password: null,
        customerID: null,
        isOMS: true,
        isNeedCollectDeliveryPrice: false,
        isPickup: false,
      }),
        (this.my_express_edit.accountDIalog = true);
      this.my_express_edit.dialog = false;
      const responseExpress = await this.get_branch_express_list();

      //ยัดexpress เข้า dropdown
      this.dialog_data.expressCompanyID = { id: 0, name: "--ทั้งหมด--" };
      this.dialog_data.expressCompanyList = [{ id: 0, name: "--ทั้งหมด--" }];

      this.dialog_data.expressCompanyList = responseExpress.filter(
        (x) => x.isShowInPackForm == true && ![1].includes(x.id)
      );
      this.dialog_data.expressCompanyList.splice(0, 0, {
        id: 0,
        name: "--ทั้งหมด--",
      });

      this.dialog_data.expressCompanyID = this.my_express_edit.express_selected;
      this.vip_id_title = null;
      this.vip_password_title = null;
      this.customer_id_title = null;

      this.LoginEmail_or_ViP_ID_IsShow = true;
      this.Key_or_ViP_Password_IsShow = true;
      this.CustomerID_IsShow = true;

      //เก็บไว้ก่อนเผื่อใช้
      // this.dialog_data = item
      this.change_title();
    },
    async AddEditAccount() {
      if (this.dialog_data.expressCompanyID.id == 0) {
        this.$refs.expressCompanyID.focus();
        return;
      } else if (
        this.LoginEmail_or_ViP_ID_IsShow == true &&
        (this.dialog_data.viP_ID == null || this.dialog_data.viP_ID == "")
      ) {
        this.$refs.viP_ID.focus();
        return;
      } else if (
        this.Key_or_ViP_Password_IsShow == true &&
        (this.dialog_data.viP_Password == null || this.dialog_data.viP_Password == "")
      ) {
        this.$refs.viP_Password.focus();
        return;
      } else if (
        this.CustomerID_IsShow == true &&
        (this.dialog_data.customerID == null || this.dialog_data.customerID == "")
      ) {
        this.$refs.refCustomerID.focus();
        return;
      }

      var param = this.dialog_data;
      this.loading = true;
      let response = await axios.post(
        logisticService_dotnet + "Logistic/add-edit-logistic-account",
        {
          id: param.id,
          expressCompanyID: param.expressCompanyID.id,
          branchID: this.BranchID,
          shopID: null,
          isShippop: param.isShippop,
          viP_ID: param.viP_ID,
          viP_Password: param.viP_Password,
          customerID: param.customerID,
          isOMS: param.isOMS,
          isNeedCollectDeliveryPrice: param.isNeedCollectDeliveryPrice,
          isPickup: param.isPickup,
        },
        { headers: header_token }
      );
      if (response.data.status == "success") {
        if (response.data.error_description != null) {
          Swal.fire({
            icon: "warning",
            title: response.data.error_description,
            // text: response.data.error_description,
          });
          this.AlertSuccess();
          this.loading = false;
          return;
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "add-edit-logistic-account เกิดข้อผิดพลาด !!",
          text: response.data.error_description,
        });
        this.loading = false;
        return;
      }
      if (this.my_express_edit.isUpdate) {
        this.my_express_edit.dialog = true;
        this.my_express_edit.accountDIalog = false;
        await this.get_logistic_account_edit(this.my_express.BranchExpressId);
      } else {
        this.my_express_edit.accountDIalog = false;
      }
      this.loading = false;
    },

    change_title() {
      if (this.dialog_data.expressCompanyID.id !== 0) {
        if (this.dialog_data.expressCompanyID.id === 1) {
          this.vip_id_title = "PartnerID";
          this.vip_password_title = "PartnerKey";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (this.dialog_data.expressCompanyID.id === 2) {
          this.vip_id_title = "เบอร์โทร EasyShip";
          this.vip_password_title = null;
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = false;
          this.CustomerID_IsShow = false;
        } else if (this.dialog_data.expressCompanyID.id === 3) {
          this.vip_id_title = null;
          this.vip_password_title = null;
          this.customer_id_title = "เบอร์โทร Wallet@Post";

          this.LoginEmail_or_ViP_ID_IsShow = false;
          this.Key_or_ViP_Password_IsShow = false;
          this.CustomerID_IsShow = true;
        } else if (this.dialog_data.expressCompanyID.id === 7) {
          this.vip_id_title = "MerchantID";
          this.vip_password_title = "Merchant Token";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (this.dialog_data.expressCompanyID.id === 11) {
          this.vip_id_title = "ShopID";
          this.vip_password_title = "ShopName";
          this.customer_id_title = "VIP ID";

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = true;
        } else if (this.dialog_data.expressCompanyID.id === 12) {
          this.vip_id_title = "Seller ID";
          this.vip_password_title = "Seller Company Name";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (this.dialog_data.expressCompanyID.id === 13) {
          this.vip_id_title = "SoldToAccountID";
          this.vip_password_title = "PickupAccountID";
          this.customer_id_title = "ProductCode";

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = true;
        } else if (this.dialog_data.expressCompanyID.id === 17) {
          this.vip_id_title = "User ID";
          this.vip_password_title = "User Secret";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (
          this.dialog_data.expressCompanyID === 18 ||
          this.dialog_data.expressCompanyID.id === 18
        ) {
          this.vip_id_title = "Public_Key ";
          this.vip_password_title = "Secret_Key";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else {
          this.vip_id_title = null;
          this.vip_password_title = null;
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = true;
        }
        if (this.dialog_data.id == 0) {
          this.dialog_data.viP_ID = null;
          this.dialog_data.viP_Password = null;
          this.dialog_data.customerID = null;
        }
      }
    },

    SetDataLogicticAccountForadd(item) {
      if (!this.my_express_edit.isUpdate) {
        var dataAccount_list = this.my_express_edit.dataAccount_list.filter(
          (x) => x.id === item.id
        );
        this.my_express_edit.costCod = dataAccount_list[0].codCost;
        this.my_express_edit.CodPrice = dataAccount_list[0].codPercent;
        this.my_express_edit.isCostPriceEditAble =
          dataAccount_list[0].isCostPriceEditAble;
      }
    },

    SetDeliveryPrice() {
      if (this.deliveryPrice.length != 0) {
        this.deliveryPrice.headers = [
          {
            align: "center",
            text: "น้ำหนัก",
            value: "weight",
            width: "100px",
            sortable: false,
          },
        ];
        this.deliveryPrice.headers.push({
          align: "center",
          text: "ขนาด",
          sortable: false,
          value: "ขนาด",
          width: "100px",
        });

        var columns = this.deliveryPrice[0].items;
        for (var dc in columns) {
          this.deliveryPrice["headers"].push({
            align: "center",
            text: this.provinceGroup
              .filter((x) => x.id === columns[dc]["provinceGroupID"])
              .map((i) => i.description)[0],
            provinceGroupId: columns[dc]["provinceGroupID"],
            sortable: false,
            value: this.provinceGroup
              .filter((x) => x.id === columns[dc]["provinceGroupID"])
              .map((i) => i.description)[0],
            price: [
              { text: "ราคาขายร้านค้า", value: "price" },
              { text: "ต้นทุนค่าขนส่ง", value: "costPrice" },
            ],
          });
        }
      }
    },
    ChangeFollowPices(pName, index) {
      if (pName[index]["end"] === "") {
        pName.splice(index + 1);
        return;
      } else if (pName.length - 1 === index) {
        if (parseInt(pName[index]["end"]) < parseInt(pName[index]["start"])) {
          pName[index]["end"] = "";
          return;
        }
        pName.push({
          branchExpressID: this.my_express.BranchExpressId,
          branchId:this.BranchID,
          id: 0,
          start: parseInt(pName[index]["end"]) + 1,
          end: "",
          price: 0,
          no: parseInt(pName[index]["no"]) + 1,
        });
      } else if (
        parseInt(pName[index]["end"]) < parseInt(pName[index]["start"]) ||
        (pName[index + 1]["end"] != "" &&
          parseInt(pName[index]["end"]) > parseInt(pName[index + 1]["end"]) - 1)
      ) {
        pName[index]["end"] = parseInt(pName[index + 1]["start"]) - 1;
      } else {
        pName[index + 1]["start"] = parseInt(pName[index]["end"]) + 1;
      }
    },
    CheckPackingHeader(packing) {
      var postBoxHeader = [];
      if (packing === 2) {
        postBoxHeader = [
          {
            align: "center",
            text: "ลำดับที่",
            value: "orderNo",
            sortable: false,
            width: "100px",
          },
          {
            align: "left",
            text: "กล่อง",
            value: "name",
            sortable: false,
            width: "200px",
          },
          {
            align: "center",
            text: "กว้าง ",
            value: "cubic1",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ยาว",
            value: "cubic2",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "สูง",
            value: "cubic3",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ต้นทุนกล่อง",
            value: "boxCostPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาค่าแพ็ค + ค่าบรรจุภัณฑ์",
            value: "packAndBoxPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาBubble",
            value: "bubblePrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "90px",
          },
        ];
      } else {
        postBoxHeader = [
          {
            align: "center",
            text: "ลำดับที่",
            value: "orderNo",
            sortable: false,
            width: "100px",
          },
          {
            align: "left",
            text: "กล่อง",
            value: "name",
            sortable: false,
            width: "180px",
          },
          {
            align: "center",
            text: "กว้าง ",
            value: "cubic1",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ยาว",
            value: "cubic2",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "สูง",
            value: "cubic3",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ต้นทุนกล่อง",
            value: "boxCostPrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ราคาขายกล่อง",
            value: "boxSellPrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ราคาค่าแพ็ค",
            value: "packPrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ราคาBubble",
            value: "bubblePrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "90px",
          },
        ];
      }
      return postBoxHeader;
    },
    CheckEmpty(v) {
      if (v == null) {
        return false;
      } else if (v.toString() == "") {
        return false;
      } else {
        return true;
      }
    },
    AddPostBox(pName) {
      pName.push({
        // key: pName.length == 0 ? 0 : pName[pName.length - 1]["key"] + 1,
        id: 0,
        name: "",
        cubic1: null,
        cubic2: null,
        cubic3: null,
        branchExpressID: 0,
        shopExpressID: null,
        boxCostPrice: null,
        boxSellPrice: null,
        packPrice: null,
        packAndBoxPrice: null,
        bubblePrice: null,
        expressCompanyID: 0,
        orderNo: pName.length + 1,
        branchId: 0,
        shopId: 0,
        parentID: 0,
      });
    },
    RemovePostBox(pName, index) {
      Swal.fire({
        icon: "warning",
        title: "ลบ ใช่ หรือ ไม่",
        text: "ต้องการลบ " + pName[index]["name"] + " ใช่ หรือ ไม่",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.packsetting.removeItem.push(
            pName.filter((_, idex) => idex === index).map((x) => x.id)[0]
          );
          for (var i in pName) {
            if (parseInt(i) >= index) {
              pName[i]["orderNo"] = parseInt(pName[i]["orderNo"]) - 1;
            }
          }
          pName.splice(index, 1);
        }
      });
    },
    AddDeliveryPrice(dName) {
      var sizeaction = dName.filter((x) => x.size !== null);
      dName.push({
        no: dName.length + 1,
        size: sizeaction.length <= 0 ? null : dName[dName.length - 1]["size"] + 1,
        weight: dName[dName.length - 1]["weight"] + 1,
        items: [
          {
            id: 0,
            costPrice: null,
            parentID: null,
            price: null,
            provinceGroupID: this.provinceGroup
              .filter((x) => x.description === "กรุงเทพฯ")
              .map((i) => i.id)[0],
            publicPrice: null,
          },
          {
            id: 0,
            costPrice: null,
            parentID: null,
            price: null,
            provinceGroupID: this.provinceGroup
              .filter((x) => x.description === "ต่างจังหวัด")
              .map((i) => i.id)[0],
            publicPrice: null,
          },
        ],
      });
    },
    RemoveDeliveryPrice(dName, index) {
      Swal.fire({
        icon: "warning",
        title: "ลบ ใช่ หรือ ไม่",
        text: "ต้องการลบน้ำหนัก " + dName[index]["weight"] + " ใช่ หรือ ไม่",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var results = [dName[index].items[0].id, dName[index].items[1].id];
          for (var i in results) {
            this.removeDeliveryPrice.push(results[i]);
          }
          dName.splice(index, 1);
        }
      });
    },
    SetOrderNo(pName, index) {
      if (
        parseInt(pName[index]["orderNo"]) > 0 &&
        parseInt(pName[index]["orderNo"]) <= pName.length
      ) {
        var num = [index, index];

        // หาตำแหน่งที่จะไปแทนที่
        for (var i in pName) {
          if (
            parseInt(index) != parseInt(i) &&
            parseInt(pName[index]["orderNo"]) == pName[i]["orderNo"]
          ) {
            num[1] = parseInt(i);
            break;
          }
        }
        if (num[0] != num[1]) {
          // ลบตำแหน่งตัวเอง
          var list = pName[index];
          pName.splice(index, 1);

          // เพิ่มตำแหน่งที่จะไป
          pName.splice(num[1], 0, list);

          // เรียง orderNo ใหม่
          for (var t in pName) {
            pName[t]["orderNo"] = parseInt(t) + 1;
          }
        }
      }
    },
    SetRulesInterExpress() {
      this.interExpressOption.rulesServiceCode = [(v) => Trim_rules(v) || "กรุณาระบุ"];
      this.interExpressOption.rulesServiceControlCode = [
        (v) => Trim_rules(v) || "กรุณาระบุ",
      ];
      this.interExpressOption.rulesTemperatureControlCode = [
        (v) => Trim_rules(v) || "กรุณาระบุ",
      ];
      this.interExpressOption.rulesTemperatureTypeCode = [
        (v) => Trim_rules(v) || "กรุณาระบุ",
      ];
    },
    ClearRules() {
      this.my_express_edit.rulesAccount = [];
      this.my_express_edit.rulesCodPersent = [];
      this.my_express_edit.rulesExpress = [];
      this.my_express_edit.expressValidateText = "";
      this.my_express_edit.accountValidateText = "";
      this.my_express_edit.codPersentValidateText = "";
      this.interExpressOption.rulesServiceCode = [];
      this.interExpressOption.rulesServiceControlCode = [];
      this.interExpressOption.rulesTemperatureControlCode = [];
      this.interExpressOption.rulesTemperatureTypeCode = [];
    },
    checklog(itemID) {
      // console.log(
      //   "%c" + itemID,
      //   `background: #000000; color: #FFF; padding: 4px; border-radius: 2px`
      // );
    },
    Trim_value,
    Trim_rules,
    isNumberWithDot,
    FormatInvoice,
    FormatOrder,
    formatMoney,
    isNumberWNoDot,
    set_format_date_time,
    format_price,
    format_vat_type,
    AlertSuccess,
    AlertWarning,
    AlertError,
  },
};
</script>

<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}

.mobile-none {
  display: ;
}

.pc-none {
  display: none;
}

@media (max-width: 599px) {
  .mobile-none {
    display: none;
  }

  .pc-none {
    display: ;
  }
}
</style>
